/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: zer0nim (https://sketchfab.com/zer0nim)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/bomberman-flying-bot-193ea9c62f5d4cd2b0a9f695156bac84
title: Bomberman Flying Bot
*/

import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { useEffect } from 'react'
import { useState } from 'react'
import robotAaa from './../../assets/audio/robot-aaa.wav'
import robotUii from './../../assets/audio/robot-uii.wav'

const Bomberman = (props) => {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/3d-models/bomberman.glb')
  const { actions, names } = useAnimations(animations, group)
  const [animated, setAnimated] = useState(false)

  function playRobotAaa() {
    var audio = new Audio(robotAaa);
    audio.play();
  }

  function playRobotUii() {
    var audio = new Audio(robotUii);
    audio.play();
  }

  const animatedModels = () => {
    actions[names[2]].reset().fadeIn(0.5).play();
    actions[names[2]].setLoop(1, 1);
    actions[names[2]].clampWhenFinished = true;
    actions[names[2]].timeScale = 1
    setAnimated(true);
    playRobotAaa()
  }
  

  useEffect(() => {
    actions[names[0]].reset().fadeIn(0.5).play(); 
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (animated === true) {
      setTimeout(() => {
        actions[names[2]].setLoop(0, 0);
        actions[names[2]].clampWhenFinished = false;
        actions[names[2]].timeScale = -1
        actions[names[2]].paused = false
        setAnimated(false)
        playRobotUii()
      }, 2000)
    }
    // eslint-disable-next-line
  }, [animatedModels])

  return (
    <group ref={group} {...props} dispose={null} onClick={ () => animatedModels() }>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group name="Armature" rotation={[-Math.PI / 2, 0, 0]} scale={1.3}>
            <primitive object={nodes._rootJoint} />
            <skinnedMesh
              geometry={nodes.Object_15.geometry}
              material={nodes.Object_15.material}
              skeleton={nodes.Object_15.skeleton}
            />
            <skinnedMesh
              geometry={nodes.Object_16.geometry}
              material={nodes.Object_16.material}
              skeleton={nodes.Object_16.skeleton}
            />
            <skinnedMesh
              geometry={nodes.Object_18.geometry}
              material={nodes.Object_18.material}
              skeleton={nodes.Object_18.skeleton}
            />
            <skinnedMesh
              geometry={nodes.Object_19.geometry}
              material={nodes.Object_19.material}
              skeleton={nodes.Object_19.skeleton}
            />
            <skinnedMesh
              geometry={nodes.Object_20.geometry}
              material={nodes.Object_20.material}
              skeleton={nodes.Object_20.skeleton}
            />
            <skinnedMesh
              geometry={nodes.Object_22.geometry}
              material={nodes.Object_22.material}
              skeleton={nodes.Object_22.skeleton}
            />
            <skinnedMesh
              geometry={nodes.Object_23.geometry}
              material={nodes.Object_23.material}
              skeleton={nodes.Object_23.skeleton}
            />
            <skinnedMesh
              geometry={nodes.Object_24.geometry}
              material={nodes.Object_24.material}
              skeleton={nodes.Object_24.skeleton}
            />
            <skinnedMesh
              geometry={nodes.Object_26.geometry}
              material={nodes.Object_26.material}
              skeleton={nodes.Object_26.skeleton}
            />
            <skinnedMesh
              geometry={nodes.Object_27.geometry}
              material={nodes.Object_27.material}
              skeleton={nodes.Object_27.skeleton}
            />
            <skinnedMesh
              geometry={nodes.Object_28.geometry}
              material={nodes.Object_28.material}
              skeleton={nodes.Object_28.skeleton}
            />
            <skinnedMesh
              geometry={nodes.Object_30.geometry}
              material={nodes.Object_30.material}
              skeleton={nodes.Object_30.skeleton}
            />
            <skinnedMesh
              geometry={nodes.Object_31.geometry}
              material={nodes.Object_31.material}
              skeleton={nodes.Object_31.skeleton}
            />
            <skinnedMesh
              geometry={nodes.Object_32.geometry}
              material={nodes.Object_32.material}
              skeleton={nodes.Object_32.skeleton}
            />
            <skinnedMesh
              geometry={nodes.Object_34.geometry}
              material={nodes.Object_34.material}
              skeleton={nodes.Object_34.skeleton}
            />
            <skinnedMesh
              geometry={nodes.Object_36.geometry}
              material={nodes.Object_36.material}
              skeleton={nodes.Object_36.skeleton}
            />
            <skinnedMesh
              geometry={nodes.Object_51.geometry}
              material={materials.Target}
              skeleton={nodes.Object_51.skeleton}
            />
          </group>
        </group>
      </group>
    </group>
  )
}

export default Bomberman

useGLTF.preload('/3d-models/bomberman.glb')
