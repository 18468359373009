
import portalImg from './../assets/images/project-images/3D-Portal.webp';
import mindCrashImg from './../assets/images/project-images/Mindcrash.webp';
import portfolioImg from './../assets/images/project-images/My-Portfolio.webp';
import rebillImg from './../assets/images/project-images/ReBill.webp';
import rebillPosImg from './../assets/images/project-images/ReBill-PoS.webp';
import eatscapeImg from './../assets/images/project-images/Eatscape.webp';
import elcImg from './../assets/images/project-images/ELC-English-Language-Center.webp';


export const dataProjects = [
    {
        id: 1,
        name: '3D-Portal',
        image: portalImg,
        desc: "It is a 3D Portal site that I create using ReactJS and ThreeJS following the tutorial on <a href='https://www.youtube.com/watch?v=kZ5M2Y_0wh0' target='__blank' class='font-bold'>its Youtube!</a>",
        languages: [
            'ReactJS',
            'ThreeJS',
            'Finished'
        ],
        liveLink: 'https://ferii007.github.io/3d-portal/',
        codeLink: 'https://github.com/ferii007/3d-portal'
    },

    {
        id: 2,
        name: 'Mindcrash',
        image: mindCrashImg,
        desc: "It is a Minecraft duplicate that I create by following the tutorial on <a href='https://www.youtube.com/watch?v=qpOZup_3P_A' target='__blank' class='font-bold'>its Youtube!</a>",
        languages: [
            'ReactJS',
            'ThreeJS',
            'JavaScript',
            'CSS',
            'Finished'
        ],
        liveLink: 'https://ferii007.github.io/mindcrash/',
        codeLink: 'https://github.com/ferii007/mindcrash'
    },

    {
        id: 3,
        name: 'My Portfolio',
        image: portfolioImg,
        desc: "My First Portfolio Website",
        languages: [
            'HTML',
            'JavaScript',
            'ThreeJS',
            'ReactJS',
            'TailwindCSS',
            'Lottie',
            'Axios',
            'API',
            'Ongoing'
        ],
        liveLink: 'https://google.com',
        codeLink: null
    },

    {
        id: 3,
        name: 'Eatscape',
        image: eatscapeImg,
        desc: "Ultimate culinary landing page, showcasing a delightful array of fast food and top-notch restaurant menus from around the world.",
        languages: [
            'HTML',
            'JavaScript',
            'ReactJS',
            'TailwindCSS',
            'API',
            'Ongoing'
        ],
        liveLink: 'https://ferii007.github.io/food-landing-page/',
        codeLink: 'https://github.com/ferii007/food-landing-page'
    },

    {
        id: 4,
        name: 'ReBill',
        image: rebillImg,
        desc: "A company that offers a comprehensive solution for both online and offline commerce",
        languages: [
            'HTML',
            'CSS',
            'JavaScript',
            'TailwindCSS',
            'Laravel',
            'API',
            'Ongoing'
        ],
        liveLink: 'https://rebill-pos.com',
        codeLink: null
    },

    {
        id: 5,
        name: 'ReBill-PoS',
        image: rebillPosImg,
        desc: "ReBill-PoS is a cloud-based point of sale system designed to help you manage your offline store",
        languages: [
            'HTML',
            'CSS',
            'JavaScript',
            'Bootstrap',
            'Laravel',
            'ReactJS',
            'API',
            'Ongoing'
        ],
        liveLink: 'https://pos.rebill-pos.com',
        codeLink: null
    },

    {
        id: 6,
        name: 'ELC - English Language Center',
        image: elcImg,
        desc: "ELC is represented by major educational consultants and wholesale travel agencies in Europe, Asia, Latin America, Eastern Europe, Canada, the Middle East, and Africa.",
        languages: [
            'WordPress'
        ],
        liveLink: 'https://elc.edu',
        codeLink: null
    },
];
