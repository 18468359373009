
import { useRef } from "react";
import { useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { Environment, Loader } from "@react-three/drei";
import { Suspense } from "react";
import Bomberman from "./Bomberman";
import Cloud from "./Cloud";
import HeroBtn from "./HeroBtn";


const HeroSection = ({ footerRef }) => {

    const bombermanModelsRef = useRef(0)

    useEffect(() => {
        var heroSection = document.getElementsByClassName('hero-section-bg')[0];
        setTimeout(() => {
            heroSection.onmouseenter = function() {
                heroSection.onmousemove = function(e) {
                    var heroClientX = (e.clientX / 700) - ((heroSection.clientWidth / 2) / 700);
                    var heroClientY = (e.clientY / 500) - ((heroSection.clientHeight / 2) / 500)
                    bombermanModelsRef.current.rotation.y = heroClientX
                    bombermanModelsRef.current.rotation.x = heroClientY
                }
            }
        }, 1000);
    }, [])


        
    return (
        <section className="hero-section-bg">
            <div className="bg-gradient-front" />
            <div className="city" />
            <div className="cloud">
                <Cloud />
            </div>
            <div className="max-width-01 h-screen">

                <div className="box-circle">
                    <div className="circle-light dark:circle-dark" />

                    <div className="three-3d">
                        <Canvas camera={{ position: [0, 0, 2], fov: 55 }} className="lg:cursor-pointer">
                            <Suspense fallback={null}>
                                <group position={[0, -0.5, 0]} ref={bombermanModelsRef}>
                                    <Bomberman />
                                </group>
                                <Environment preset="dawn" />
                            </Suspense>
                        </Canvas>

                        <Loader />
                    </div>
                    
                    <div className="gear-box-sm" />
                    <div className="gear-box-lg" />

                    <div className="hero-neon-title-box">
                        <h1 className="hero-neon-title">
                            <span>FERI</span>
                            <span>RAMDANI</span>
                        </h1>
                    </div>

                    <HeroBtn footerRef={footerRef} />
                </div>
            </div>
        </section> 
    )

}

export default HeroSection

                    