
import { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import { dataProjects } from '../../data/dataProjects';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { gsap, Back } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


const ProjectSection = () => {

    let projectsRef = useRef(null);
    let projectsTitleRef = useRef(null);

    const [projectCardIndex, setProjectCardIndex] = useState(0);
    const settings = {
        focusOnSelect: true,
        initialSlide: 0,
        infinite: true,
        lazyLoad: true,
        speed: 300,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: 0,
        responsive: [
            {
                breakpoint: 860,
                settings: {
                    slidesToShow: 2,
                    centerMode: false,
                }
            },
            
            {
                breakpoint: 735,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                }
            },         
        ],

        beforeChange: (current, next) => setProjectCardIndex(next),
    };

    useEffect(() => {

        gsap.context(() => {
            let timeLine = gsap.timeline({
                scrollTrigger: {
                    trigger: projectsTitleRef,
                    start: 'top'
                }
            });
    
            timeLine.from(projectsTitleRef, 
                {
                    opacity: 0,
                    y: -100,
                    duration: 2,
                    ease: Back.easeOut,
                }
            );

            timeLine.from(projectsRef, 
                {
                    opacity: 0,
                    x: 100,
                    duration: 2,
                    ease: Back.easeOut,
                },
                ">-1.9"
            );
        });

    }, []);

    return(
        <section>
            <div className="max-width-01 pt-28 md:pt-36 overflow-hidden">
                <div className="pl-8" ref={timeLite => projectsTitleRef = timeLite}>
                    <h1 className="flex flex-col text-[2.50rem] md:text-5xl font-righteous text-textPrimaryDark dark:text-textPrimaryLight leading-tight transition-colors-1">
                        <span>Some things</span> 
                        <span>I've worked on</span>
                    </h1>
                </div>

                <div className='w-screen px-0 py-20 md:py-0 pt-20 md:pt-24 overflow-x-hidden md:overflow-hidden' ref={timeLite => projectsRef = timeLite}>
                    <Slider {...settings}>
                        {
                            dataProjects.map((dataProject, i) => (
                                <div key={dataProject.id} className='px-4 md:px-8 lg:px-0 cursor-pointer'>
                                    <div className={`${i === projectCardIndex ? "project-card project-card-active" : "project-card"}`}>
                                        <div className='p-3'>
                                            <LazyLoadImage src={dataProject.image} alt={`${dataProject.name} Image`} className='w-full h-[26rem] object-cover object-center project-card-img mb-3' />
                                            
                                            <div className='px-2 pb-24'>
                                                <h1 className='text-[1.65rem] font-righteous text-textPrimaryDark dark:text-textPrimaryLight tracking-wider mt-3 transition-colors-1'>{dataProject.name}</h1>

                                                <div className='flex flex-wrap items-center gap-2 text-xs tracking-wider cursor-default mt-2 mb-5'>
                                                    {
                                                        dataProject.languages.map((language, i) => (
                                                            <div key={i} className={`${((language === 'ReactJS') || (language === 'Bootstrap') || (language === 'Laravel') || (language === 'TailwindCSS')) ? 'project-lang-framework' : ((language === 'Lottie') || ((language === 'Axios'))) ? 'project-lang-package' : 'project-lang-base' } rounded-full transition-colors-1`}>
                                                                <span className='block px-3 mt-[0.10rem]'>{language}</span>
                                                            </div>
                                                        ))
                                                    }
                                                </div>

                                                <p className='text-textQuaternaryLight dark:text-textQuaternaryDark font-medium text-lg leading-relaxed transition-colors-1' dangerouslySetInnerHTML={{ __html: dataProject.desc }} />     
                                            </div>
                                        </div>

                                        {
                                            dataProject.name === 'My Portfolio' ? 
                                            (
                                                <div className='flex text-xl font-medium tracking-widest absolute bottom-0 w-full p-3'>
                                                    <div className='bg-colorLight dark:bg-colorDark bg-opacity-70 dark:bg-opacity-70 text-white rounded-lg text-center w-full cursor-not-allowed'>
                                                        <a href={dataProject.liveLink} target='__blank' className='pointer-events-none inline-block w-full h-full px-4 py-2'>
                                                            You Are Here Now
                                                        </a>
                                                    </div>
                                                </div>
                                            )

                                            :

                                            (
                                                <div className='flex gap-3 text-xl font-medium tracking-widest text-textPrimaryLight absolute bottom-0 w-full p-3'>
                                                    <div className={`rounded-lg text-center w-auto ${dataProject.codeLink === null ? 'cursor-not-allowed bg-colorPrimaryLight' : 'cursor-pointer hover:bg-opacity-80 dark:hover:bg-opacity-80 bg-colorLight2 dark:bg-colorDark2'}`}>
                                                        <a href={dataProject.codeLink} target='__blank' className={`inline-block w-full h-full px-4 py-2 ${dataProject.codeLink === null ? 'hover:pointer-events-none' : 'hover:pointer-events-auto'}`}>
                                                            Code
                                                        </a>
                                                    </div>

                                                    <div className='bg-colorLight dark:bg-colorDark rounded-lg text-center cursor-pointer w-full hover:bg-opacity-80 dark:hover:bg-opacity-80'>
                                                        <a href={dataProject.liveLink} target='__blank' className='inline-block w-full h-full px-4 py-2'>
                                                            Live View
                                                        </a>
                                                    </div>
                                                </div> 
                                            )
                                        }

                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>
        </section>
    )

}

export default ProjectSection
