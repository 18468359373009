
import Contact from "./contact/Contact";
import Stars from "./planets/Stars";

const FooterSection = () => {

    return(
        <section className="mt-52 relative">
            <div className="max-width-01 px-4 lg:px-8 py-28 md:py-44 lg:py-56 footer-section">
                <div className="pb-28">
                    <Contact />
                </div>
            </div>

            <div className="max-width-01 w-full absolute bottom-0 left-0 right-0">
                <div className="flex justify-center items-center py-8 lg:py-4">
                    <p className="font-fredoka-one tracking-widest text-sm lg:text-lg text-[#ACACAC] dark:text-[#6b6b6b]">&#169; Feri Ramdani</p>
                </div>
            </div>

            <div>
                <Stars />
            </div>
        </section>
    )

}

export default FooterSection