

import { useRef, useState } from "react"
import { gsap, Back } from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import emailjs from '@emailjs/browser'
import { useForm } from "react-hook-form"
import { socialIconsLight, socialIconsDark } from "../../../data/dataSocialLinks"
import { LazyLoadImage } from "react-lazy-load-image-component"
import cv from "./../../../assets/cv.pdf"
import cvIconLight from './../../../assets/images/icon-social/light/cv.svg'
import cvIconDark from './../../../assets/images/icon-social/dark/cv.svg'
import { useEffect } from "react"
import Earth from "../planets/Earth"
gsap.registerPlugin(ScrollTrigger)


const Contact = () => {

    let footerContactHeaderRef = useRef(null)
    let footerContactFormRef = useRef(null)

    const { register, handleSubmit, formState: {errors}, reset } = useForm()

    const [loadingSendEmail, setLoadingSendEmail] = useState('')
    const [successSendEmail, setSuccessSendEmail] = useState(false)
    const [failedSendEmail, setFailedSendEmail] = useState(false)
    
    const form = useRef()

    const sendEmail = (e) => {
        emailjs.sendForm('service_jghaqi5', 'template_mtfehre', form.current, 'AOUX-8p3JoveBDhpV')
        .then((result) => {
            setSuccessSendEmail(true)

            setTimeout(() => {
                setSuccessSendEmail(false)
                setLoadingSendEmail('')
            }, 3000);
        }, (error) => {
            setFailedSendEmail(true)

            setTimeout(() => {
                setFailedSendEmail(false)
                setLoadingSendEmail('')
            }, 3000);
        });
        setLoadingSendEmail('Sending Messages...')
        reset()
    };

    useEffect(() => {

        const footerContactTitleRef = footerContactHeaderRef.children[0];
        const footerContactSubTitleRef = footerContactHeaderRef.children[1];

        gsap.context(() => {
            let timeLine = gsap.timeline({
                scrollTrigger: {
                    trigger: footerContactHeaderRef,
                    start: 'center center'
                }
            });

            timeLine.from(footerContactTitleRef, 
                {
                    opacity: 0,
                    y: -50,
                    ease: Back.easeOut,
                    duration: 1.4
                }
            );

            timeLine.from(footerContactSubTitleRef, 
                {
                opacity: 0,
                y: -50,
                ease: Back.easeOut,
                duration: 1.4
                },
                ">-1.2"
            );

            timeLine.from(footerContactFormRef, 
                {
                    opacity: 0,
                    y: -50,
                    ease: Back.easeOut,
                    duration: 1.4
                },
                ">-1"
            );
        });

    }, [])
 

    return(
        <div className="md:px-20 lg:px-0">
            <div ref={timeLite => footerContactHeaderRef = timeLite}>
                <p className="text-lg md:text-2xl font-righteous text-textPrimaryDark dark:text-textPrimaryLight leading-tight mb-2 md:mb-5">Found some bugs or need something?</p>
                <h1 className="text-[2.50rem] md:text-6xl font-righteous text-textPrimaryDark dark:text-textPrimaryLight leading-tight">Contact Me</h1>
            </div>

            <div className="flex justify-center lg:justify-start relative" ref={timeLite => footerContactFormRef = timeLite}>
                <form ref={form} onSubmit={handleSubmit(sendEmail)} className="contact-card">
                    <div className={`contact-field ${errors.name ? 'border border-red-600 dark:border-red-500' : 'border-none'}`}>
                        <label className="block">
                            <div className="flex items-center justify-between">
                                <span className="contact-span">Name :</span>

                                {
                                    errors.name && (
                                        <span className="contact-span-error">{errors.name.message}</span>
                                    )
                                }
                            </div>

                            <input type="text" name="name" className="contact-input" autoComplete="off" {...register("name", {required: "Sorry, you need to input the Name field"})} />
                        </label>
                    </div>

                    <div className={`contact-field ${errors.email ? 'border border-red-600 dark:border-red-500' : 'border-none'}`}>
                        <label className="block">
                            <div className="flex items-center justify-between">
                                <span className="contact-span">Email :</span>

                                {
                                    errors.email && (
                                        <span className="contact-span-error">{errors.email.message}</span>
                                    )
                                }
                            </div>

                            <input type="text" name="email" className="contact-input" autoComplete="off" {...register("email", {required: "*Sorry, you need to input the Email field", pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Please Insert Valid Email!'}})} />
                        </label>
                    </div>

                    <div className={`contact-field ${errors.messages ? 'border border-red-600 dark:border-red-500' : 'border-none'}`}>
                        <label className="block">
                            <div className="flex justify-between items-center">
                                <span className="contact-span">Messages :</span>

                                {
                                    errors.messages && (
                                        <span className="contact-span-error">{errors.messages.message}</span>
                                    )
                                }
                            </div>

                            <textarea name="messages" className="contact-input h-44 md:h-56 lg:h-72" autoComplete="off" {...register("messages", {required: "*Plesase write your messages"})} />
                        </label>
                    </div>

                    <div className="flex justify-between my-2">
                        <div className="flex items-center gap-3 dark:hidden">
                            {
                                socialIconsLight.map((socialIconLight, i) => (
                                    <a href={socialIconLight.link} key={i} target="_blank" rel="noopener noreferrer" aria-label={socialIconLight.label} className="hover:opacity-60 hover:scale-90 transition-all duration-300">
                                        <LazyLoadImage src={socialIconLight.src} alt={socialIconLight.name} className={`${socialIconLight.size}`} />
                                    </a>
                                ))
                            }

                            <a href={cv} download="My CV" aria-label={'Download my CV'} className="hover:opacity-60 hover:scale-90 transition-all duration-300">
                                <LazyLoadImage src={cvIconLight} alt="CV Icon" className="w-8" />
                            </a>
                        </div>

                        <div className="hidden items-center gap-3 dark:flex">
                            {
                                socialIconsDark.map((socialIconDark, i) => (
                                    <a href={socialIconDark.link} key={i} target="_blank" rel="noopener noreferrer" aria-label={socialIconDark.label} className="hover:opacity-60 hover:scale-90 transition-all duration-300">
                                        <LazyLoadImage src={socialIconDark.src} alt={socialIconDark.name} className={`${socialIconDark.size}`} />
                                    </a>
                                ))
                            }

                            <a href={cv} download="My CV" aria-label={'Download my CV'} className="hover:opacity-60 hover:scale-90 transition-all duration-300">
                                <LazyLoadImage src={cvIconDark} alt="CV Icon" className="w-8" />
                            </a>
                        </div>

                        <button type="submit" className="contact-send-btn block relative justify-end bg-colorLight dark:bg-colorDark text-textPrimaryLight px-9 py-[.40rem] rounded-xl overflow-hidden group">
                            <div className="contact-send-btn-wrap" />
                                <p className="relative group-hover:text-white dark:group-hover:text-[#2E3949] transition-all duration-500 ease-in-out">Send</p>  
                        </button>
                    </div>

                    <div className="text-textPrimaryDark dark:text-textPrimaryLight pb-1">
                        <p className={`${successSendEmail === false ? 'block' : 'hidden'} ${failedSendEmail === false ? 'block' : 'hidden'}`}>
                            {loadingSendEmail}
                        </p>

                        <p className={`${successSendEmail === true ? 'block' : 'hidden'}`}>
                            Thank you for contacting me. I will reply to your messages
                        </p>

                        <p className={`${failedSendEmail === true ? 'block' : 'hidden'}`}>
                            I'm sorry the messages failed to send
                        </p>
                    </div>
                </form>
                
                <div className="h-[40rem] md:h-[50rem] absolute top-[-10%] right-0 md:-right-1/2 2xl:-right-[40rem] 3xl:ri left-0 -z-10 ">
                    <Earth />
                </div>
            </div>
        </div>
    )

}

export default Contact