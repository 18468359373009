
import { useEffect, useRef } from "react"
import { gsap, Back } from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
// import AboutSectionAnimation from "./AboutSectionAnimation"
import AboutSectionSkills from "./AboutSectionSkills"
// import HorizontalAboutSection from "./HorizontalAboutSection"
// import roadmapMobileLight from "./../../assets/images/roadmaps/roadmap-mobile-light.png"
// import roadmapMobileDark from "./../../assets/images/roadmaps/roadmap-mobile-dark.png"
gsap.registerPlugin(ScrollTrigger)


const AboutSection = () => {

    let aboutHeaderRef = useRef(null);
    let aboutDescRef = useRef(null);
    let aboutTitleSkillRef = useRef(null);

    useEffect(() => {

        const aboutTitleRef = aboutHeaderRef.children[0];
        const aboutSubTitleRef = aboutHeaderRef.children[1];

        gsap.context(() => {
            let timeLine = gsap.timeline({
                scrollTrigger: {
                    trigger: aboutHeaderRef,
                    start: 'center center'
                }
            });

            timeLine.from(aboutTitleRef, 
                {
                    opacity: 0,
                    y: -50,
                    ease: Back.easeOut,
                    duration: 1.4
                }
            );

            timeLine.from(aboutSubTitleRef, 
                {
                opacity: 0,
                y: -50,
                ease: Back.easeOut,
                duration: 1.4
                },
                ">-1.2"
            );

            timeLine.from(aboutDescRef, 
                {
                opacity: 0,
                y: -70,
                ease: Back.easeOut,
                duration: 1.5
                },
                ">-1.1"
            );
        });

        gsap.context(() => {
            let timeLine = gsap.timeline({
                scrollTrigger: {
                    trigger: aboutTitleSkillRef,
                    start: 'center center'
                }
            });

            timeLine.from(aboutTitleSkillRef, 
                {
                opacity: 0,
                y: -70,
                ease: Back.easeOut,
                duration: 1.5
                }
            );
        });

    }, []);


    return(
        <section>
            <div className="max-width-01 pt-28 md:pt-36">
                <div className="flex flex-col items-center text-center" ref={timeLite => aboutHeaderRef = timeLite}>
                    <h1 className="text-about-title transition-colors-1">About Me</h1>

                    <h2 className="text-about-sub-title transition-colors-1">Let's be friend!</h2>
                </div>

                <div className="flex flex-col md:flex-row items-start pt-14 relative">
                    {/* <div className="hidden md:block sticky top-16 lg:top-0 w-[90rem] lg:w-[80rem] -ml-[8.5rem] lg:-ml-0 lg:-translate-x-[7.5rem]">
                        <AboutSectionAnimation />
                    </div> */}

                    <div className="text-about-content">
                        <div className="px-4 md:px-8 transition-colors-1 lg:w-2/3">
                            <p className="text-xl" ref={timeLite => aboutDescRef = timeLite}>
                                <span className="text-6xl md:text-8xl mr-5 float-left font-matin">H</span>ello World!!
        
                                <span> My Name's Feri Ramdani, ussually called Feri or Pei.</span>
                                <br className="block md:hidden" />
                                {/* <br className="block md:hidden" /> */}
                                <span className="block">
                                    I am a Junior Web Developer with a strong passion for delivering creative and 
                                    functional digital solutions, where I have gained a profound understanding of web programming.
                                    <br />
                                    I am enthusiastic about continuously developing myself as a Web Developer and contributing to creating engaging and beneficial online experiences.
                                </span>
                            </p>
                        </div>

                        <div className="pt-14 px-4 md:px-8">
                            <h1 className="text-about-title text-3xl transition-colors-1" ref={timeLite => aboutTitleSkillRef = timeLite}>My Skills</h1>

                            <div className="flex mt-10">
                                <AboutSectionSkills />
                            </div>                      
                        </div>
                    </div>
                </div>

                {/* <div className="hidden md:block">
                    <HorizontalAboutSection />
                </div> */}

                {/* <div className="block md:hidden pt-12">
                    <img src={roadmapMobileLight} alt="" className="w-screen block dark:hidden object-cover" />
                    <img src={roadmapMobileDark} alt="" className="w-screen hidden dark:block object-cover" />
                </div> */}
            </div>
        </section>
    )

}

export default AboutSection
