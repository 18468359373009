
import githubIconLight from './../assets/images/icon-social/light/github.svg';
import linkedInIconLight from './../assets/images/icon-social/light/linkedin.svg';
import whatsAppIconLight from './../assets/images/icon-social/light/whatsapp.svg';

import githubIconDark from './../assets/images/icon-social/dark/github.svg';
import linkedInIconDark from './../assets/images/icon-social/dark/linkedin.svg';
import whatsAppIconDark from './../assets/images/icon-social/dark/whatsapp.svg';


export const socialIconsLight = [
    {
        name: 'Github Icon',
        label: 'Profile Github',
        src: githubIconLight,
        size: 'w-10',
        position: '',
        link: 'https://github.com/ferii007'
    },

    {
        name: 'Linkedin Icon',
        label: 'Linkedin',
        src: linkedInIconLight,
        size: 'w-10',
        position: '',
        link: 'https://www.linkedin.com/in/feri-ramdani'
    },

    {
        name: 'WhatsApp Icon',
        label: 'WhatsApp',
        src: whatsAppIconLight,
        size: 'w-8',
        position: '',
        link: 'https://wa.me/6285722805861?text=Hello!'
    },
];

export const socialIconsDark = [
    {
        name: 'Github Icon',
        label: 'Profile Github',
        src: githubIconDark,
        size: 'w-10',
        position: '',
        link: 'https://github.com/'
    },

    {
        name: 'Linkedin Icon',
        label: 'Linkedin',
        src: linkedInIconDark,
        size: 'w-10',
        position: '',
        link: 'https://www.linkedin.com/'
    },

    {
        name: 'WhatsApp Icon',
        label: 'WhatsApp',
        src: whatsAppIconDark,
        size: 'w-8',
        position: '',
        link: 'https://www.whatsapp.com/'
    },
];