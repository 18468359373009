
import { useEffect } from "react"
import mailIcon from "./../../assets/images/mail.svg"

const HeroBtn = ({ footerRef }) => {

    const handleScrollToFooter = (ref) => {
        window.scrollTo({
            top: ref.offsetTop + 250,
            left: 0,
            behavior: "smooth"
        });
    }

    useEffect(() => {
        const heroHeader = document.querySelector('.cloud');
        const heroBtn = document.querySelector('.hero-btn');
        const footerSection = document.querySelector('.footer-section');

        const moveBtnIfInHeader = (e) => {
            if (!e[0].isIntersecting) {
                heroBtn.classList.add('hero-btn-floating');
            }else {
                heroBtn.classList.remove('hero-btn-floating');
            }
        }

        const hiddenBtnIfInFooter = (e) => {
            if (e[0].isIntersecting) {
                heroBtn.classList.add('hero-btn-hidden');
            }else {
                heroBtn.classList.remove('hero-btn-hidden');
            }
        }

        const moveBtnIntersenctionHeaderObs = new IntersectionObserver(moveBtnIfInHeader);
        const moveBtnIntersenctionFooterObs = new IntersectionObserver(hiddenBtnIfInFooter);

        moveBtnIntersenctionHeaderObs.observe(heroHeader)
        moveBtnIntersenctionFooterObs.observe(footerSection)
    }, [])


    return(
        <div className="hero-btn-container">
            <div className="hero-btn hero-btn-gradient-light dark:hero-btn-gradient-dark cursor-pointer" onClick={() => handleScrollToFooter(footerRef.current)}>
                <img src={mailIcon} alt="Mail Icon" className="hero-btn-envelope" />
                <span className="hero-btn-span-gradient-light dark:hero-btn-span-gradient-dark">Contact Me</span>
            </div>
        </div>
    )
}

export default HeroBtn