
import { useEffect } from "react";
// import IntroScreen from "./components/intro-screen/IntroScreen";
import DarkLightModeToggle from './components/dark-light-mode/DarkLightModeToogle';
import HeroSection from './components/hero-section/HeroSection';
import AboutSection from './components/about-section/AboutSection';
import ProjectSection from './components/project-section/ProjectSection';
import FooterSection from './components/footer-section/FooterSection';
import { useRef } from "react";


function App() {

  // const [loading, setLoading] = useState(true);
  const footerRef = useRef(0)

  useEffect(() => {

    // setLoading(false);


    setTimeout(() => {
      // setLoading(false);
    }, 15700);


  }, []);


  return (
    <>
      {/* {
        loading ? 
        (

          <IntroScreen />

        )

        :

        ( */}
          <>
            <HeroSection footerRef={footerRef} />

            <AboutSection />

            <ProjectSection />

            <div ref={footerRef}>
              <FooterSection />
            </div>


            <DarkLightModeToggle />
          </>
        {/* )
      } */}

    </>
  )
}

export default App;
