
import htmlIcon from './../assets/images/icon-skills/html-icon.svg';
import cssIcon from './../assets/images/icon-skills/css-icon.svg';
import jsIcon from './../assets/images/icon-skills/js-icon.svg';
import threeJSIcon from './../assets/images/icon-skills/threejs-icon.svg';
import phpIcon from './../assets/images/icon-skills/php-icon.svg';
import reactIcon from './../assets/images/icon-skills/react-icon.svg';
import bootstrapIcon from './../assets/images/icon-skills/bootstrap-icon.svg';
import tailwindIcon from './../assets/images/icon-skills/tailwind-icon.svg';
import laravelIcon from './../assets/images/icon-skills/laravel-icon.svg';
import cypressIcon from './../assets/images/icon-skills/cypress-icon.svg';
import postmanIcon from './../assets/images/icon-skills/postman-icon.svg';
import gitIcon from './../assets/images/icon-skills/git-icon.svg';
import wordpressIcon from './../assets/images/icon-skills/wordpress-icon.svg';


export const dataSkills = [
    {
        name: 'HTML',
        wikiTitle: 'HTML',
        level: 'Expert',
        tag: 'Primary',
        icon: htmlIcon,
        id: 'skill-html'
    },

    {
        name: 'CSS',
        wikiTitle: 'CSS',
        level: 'Expert',
        tag: 'Primary',
        icon: cssIcon,
        id: 'skill-css'
    },

    {
        name: 'JS',
        wikiTitle: 'JS',
        level: 'Intermediate',
        tag: 'Primary',
        icon: jsIcon,
        id: 'skill-js'
    },

    {
        name: 'ThreeJS',
        wikiTitle: 'Three.js',
        level: 'Beginner',
        tag: 'Primary',
        icon: threeJSIcon,
        id: 'skill-threejs'
    },

    {
        name: 'PHP',
        wikiTitle: 'PHP',
        level: 'Intermediate',
        tag: 'Primary',
        icon: phpIcon,
        id: 'skill-php'
    },

    {
        name: 'ReactJS',
        wikiTitle: 'ReactJS',
        level: 'Intermediate',
        tag: 'Primary',
        icon: reactIcon,
        id: 'skill-reactjs'
    },

    {
        name: 'Bootstrap',
        wikiTitle: 'Bootstrap_(front-end_framework)',
        level: 'Expert',
        tag: 'Primary',
        icon: bootstrapIcon,
        id: 'skill-bootstrap'
    },

    {
        name: 'TailwindCSS',
        wikiTitle: 'Tailwind_CSS',
        level: 'Expert',
        tag: 'Primary',
        icon: tailwindIcon,
        id: 'skill-tailwindcss'
    },

    {
        name: 'Laravel',
        wikiTitle: 'Laravel',
        level: 'Intermediate',
        tag: 'Primary',
        icon: laravelIcon,
        id: 'skill-laravel'
    },

    {
        name: 'WordPress',
        wikiTitle: 'WordPress',
        level: 'Intermediate',
        tag: 'Primary',
        icon: wordpressIcon,
        id: 'skill-wordpress'
    },

    {
        name: 'Cypress',
        wikiTitle: 'Undefined',
        level: 'Intermediate',
        tag: 'Secondary',
        icon: cypressIcon,
        id: 'skill-cypress'
    },

    {
        name: 'Postman',
        wikiTitle: 'Postman_(software)',
        level: 'Intermediate',
        tag: 'Secondary',
        icon: postmanIcon,
        id: 'skill-postman'
    },

    {
        name: 'Git',
        wikiTitle: 'Git',
        level: 'Intermediate',
        tag: 'Secondary',
        icon: gitIcon,
        id: 'skill-git'
    },
];
