
import ReactReadMoreReadLess from "react-read-more-read-less";
import { PacmanLoader } from "react-spinners";


const SkillExplanationModal = ({getAPILoading, wikipediaResults, showWikipediaDataModal, closeWikipediaModal}) => {   

    return(
        <>
            <div className={`skill-explanation-modal z-50 ${getAPILoading ? 'block' : 'hidden'}`}>
                <div className="fixed top-0 bottom-0 left-0 right-0">
                    <div className="w-screen h-screen flex items-center justify-center">
                        <PacmanLoader size={50} color={"#36d7b7"} loading={getAPILoading} className="-translate-x-20 md:-translate-x-12" />
                    </div>
                </div>
            </div>

            <div className={`fixed h-5/6 md:h-full m-auto bottom-0 top-0 right-0 left-0 ${showWikipediaDataModal ? 'opacity-100 scale-100 pointer-events-auto' : 'opacity-0 scale-0 pointer-events-none'} transition-all duration-300 ease-linear z-[70]`}>
                <div className="max-width-01 h-full px-4 md:px-20 lg:px-72 py-14 md:py-24 lg:py-20">
                    {
                        wikipediaResults.map((wikipediaResult, i) => {
                            if (wikipediaResult.title !== 'Undefined') {
                                return(
                                    <div key={i} className="bg-bgLight2 dark:bg-bgDark2 h-full overflow-y-auto overflow-x-hidden rounded-lg scrollbar relative">
                                        {/* <div className="fixed top-0 left-0 right-0 px-4 md:px-20 lg:px-72 py-14 md:py-24 lg:py-20"> */}
                                            <div className="flex justify-between items-center border-b border-gray-500 bg-bgLight3 dark:bg-bgDark3 px-6 md:px-7 py-2 md:py-3">
                                                <h3 className="text-xl font-semibold tracking-widest capitalize">
                                                    {wikipediaResult.title}
                                                </h3>
        
                                                <button type="button" className="hover:opacity-50 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" onClick={closeWikipediaModal}>
                                                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        
                                                    <span className="sr-only">Close</span>
                                                </button>
                                            </div>
                                        {/* </div> */}
        
                                        <div className="px-7 md:px-9 py-5 md:py-7 pt-20 md:pt-24 lg:pt-20 pb-48 md:pb-40 text-justify leading-snug md:leading-loose">
                                            <ReactReadMoreReadLess charLimit={500} readMoreClassName='hidden'>
                                                {wikipediaResult.extract}
                                            </ReactReadMoreReadLess>
                                        </div>
        
                                        {/* <div className="fixed bottom-0 left-0 right-0 px-4 md:px-20 lg:px-72 py-14 md:py-24 lg:py-20"> */}
                                            <div className="absolute left-0 right-0 bottom-0 flex items-center justify-end p-3 rounded-b border-t border-gray-500 bg-bgLight3 dark:bg-bgDark3">
                                                <a className="hover:opacity-70" href={`https://en.wikipedia.org/wiki/${wikipediaResult.title}`} target='__blank'>
                                                    Read More on Wikipedia
                                                </a>
                                            </div>
                                        {/* </div> */}
                                    </div>
                                )
                            }else {
                                return(
                                    <div key={i} className="bg-bgLight2 dark:bg-bgDark2 h-full overflow-y-auto overflow-x-hidden rounded-lg scrollbar">
                                        <div className="fixed top-0 left-0 right-0 px-4 md:px-20 lg:px-72 py-14 md:py-24 lg:py-20">
                                            <div className="flex justify-between items-center border-b border-gray-500 bg-bgLight3 dark:bg-bgDark3 px-6 md:px-7 py-2 md:py-3">
                                                <h3 className="text-xl font-semibold tracking-widest capitalize">
                                                    Not Found
                                                </h3>
        
                                                <button type="button" className="hover:opacity-50 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" onClick={closeWikipediaModal}>
                                                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        
                                                    <span className="sr-only">Close</span>
                                                </button>
                                            </div>
                                        </div>
        
                                        <div className="px-7 md:px-9 py-5 md:py-7 pt-20 md:pt-24 lg:pt-20 pb-48 md:pb-40 text-justify leading-snug md:leading-loose">
                                            I'm Sorry, Wikipedia Can't Found It, You Can Looking for it From Google...
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
        </>
    )
    
}

export default SkillExplanationModal