
import { useEffect } from "react";
import lottie from "lottie-web";
import heroAnimation  from "./../../assets/animations/cloud-animation.json";


export const Cloud = () => {

    useEffect (() => {
        
        lottie.loadAnimation({
            container: document.querySelector('#hero-section-canvas'),
            animationData: heroAnimation,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            name: "Hero Animation",
        });

    }, []);


    return(
        <div id="hero-section-canvas">
        </div>
    )

}

export default Cloud
