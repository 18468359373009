
import lottie from "lottie-web";
import { useEffect } from "react";
import lightDarkModeAnimate from './../../assets/animations/light-dark-mode-animation.json';
import DarkLightMode from "./DarkLightMode";


const DarkLightModeToggle = () => {

    const [setTheme] = DarkLightMode();

    useEffect(() => {
        const checkbox = document.querySelector('#toggle');

        const lightDarkMode = lottie.loadAnimation({
            container: document.querySelector("#light-dark"),
            animationData: lightDarkModeAnimate,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            name: "Light and Dark Mode",
        });

        checkbox.addEventListener('click', function() {
            if(checkbox.checked) {
                setTheme('dark');
                lightDarkMode.playSegments([0, 25], true);
            }else {
                setTheme('light');
                lightDarkMode.playSegments([30, 50], true);
            }
        });

        if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            checkbox.checked = true;
            lightDarkMode.goToAndStop(25, true) ;
        } else {
            checkbox.checked = false;
        };

    }, [setTheme]);

    return(
        <div className="fixed bottom-5 left-5 z-[60]">
            <input type="checkbox" id="toggle" className="hidden" />
            <label htmlFor="toggle" id="light-dark" className="block w-12 md:w-14 cursor-pointer"></label>
        </div>
    )

}

export default DarkLightModeToggle