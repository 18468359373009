
import axios from 'axios';
import { gsap, Back } from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useState } from 'react';
import { dataSkills } from "../../data/dataSkills";
import SkillExplanationModal from "./modal/SkillExplanationModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
gsap.registerPlugin(ScrollTrigger)


const AboutSectionSkills = () => {

    const [showWikipediaDataModal, setShowWikipediaDataModal] = useState(false)
    const closeWikipediaModal = () => setShowWikipediaDataModal(false)
    const [wikipediaResults, setWikipediaResults] = useState([])
    const [getAPILoading, setGetAPILoading] = useState(false)

    const getWikipediaAPI = async (title) => {
        setGetAPILoading(true)

        const endpoint = `https://en.wikipedia.org/w/api.php?action=query&format=json&prop=extracts&explaintext&redirects=1&origin=*&titles=${title}&formatversion=2`

        await axios.get(endpoint)
                   .then(response => {
                        setTimeout(() => {
                            const results = response.data.query.pages
                            setWikipediaResults(results)
                            setShowWikipediaDataModal(true)
                            setGetAPILoading(false)
                        }, 500)
                   })
    }


    useEffect(() => {

        const skillHtml = document.querySelector('#skill-html')
        const skillCss = document.querySelector('#skill-css')
        const skillJs = document.querySelector('#skill-js')
        const skillThreeJS = document.querySelector('#skill-threejs')
        const skillPhp = document.querySelector('#skill-php')
        const skillReactJS = document.querySelector('#skill-reactjs')
        const skillBootstrap = document.querySelector('#skill-bootstrap')
        const skillTailwind = document.querySelector('#skill-tailwindcss')
        const skillLaravel = document.querySelector('#skill-laravel')
        const skillCypress = document.querySelector('#skill-cypress')
        const skillPostman = document.querySelector('#skill-postman')
        const skillGit = document.querySelector('#skill-git')
        const skillWordPress = document.querySelector('#skill-wordpress')

        gsap.context(() => {
            let timeLine = gsap.timeline({
                scrollTrigger: {
                    trigger: skillHtml,
                    start: 'center center center'
                }
            })

            let timeLine2 = gsap.timeline({
                scrollTrigger: {
                    trigger: skillPhp,
                    start: 'center center top'
                }
            })

            let timeLine3 = gsap.timeline({
                scrollTrigger: {
                    trigger: skillLaravel,
                    start: 'center center top'
                }
            })

            timeLine.from(skillHtml, 
                {
                    opacity: 0,
                    x: -50,
                    ease: Back.easeOut,
                    duration: 1.7
                }
            );

            timeLine.from(skillCss, 
                {
                    opacity: 0,
                    x: -50,
                    ease: Back.easeOut,
                    duration: 1.7
                },
                ">-1.3"
            );

            timeLine.from(skillJs, 
                {
                    opacity: 0,
                    x: -50,
                    ease: Back.easeOut,
                    duration: 1.7
                },
                ">-1.3"
            );

            timeLine.from(skillThreeJS, 
                {
                    opacity: 0,
                    x: -50,
                    ease: Back.easeOut,
                    duration: 1.7
                },
                ">-1.3"
            );

            timeLine2.from(skillPhp, 
                {
                    opacity: 0,
                    x: -50,
                    ease: Back.easeOut,
                    duration: 1.7
                }
            );

            timeLine2.from(skillReactJS, 
                {
                    opacity: 0,
                    x: -50,
                    ease: Back.easeOut,
                    duration: 1.7
                },
                ">-1.3"
            );

            timeLine2.from(skillBootstrap, 
                {
                    opacity: 0,
                    x: -50,
                    ease: Back.easeOut,
                    duration: 1.7
                },
                ">-1.3"
            );

            timeLine2.from(skillTailwind, 
                {
                    opacity: 0,
                    x: -50,
                    ease: Back.easeOut,
                    duration: 1.7
                },
                ">-1.3"
            );

            timeLine3.from(skillLaravel, 
                {
                    opacity: 0,
                    x: -50,
                    ease: Back.easeOut,
                    duration: 1.7
                }
            );

            timeLine3.from(skillWordPress, 
                {
                    opacity: 0,
                    x: -50,
                    ease: Back.easeOut,
                    duration: 1.7
                }
            );

            timeLine3.from(skillCypress, 
                {
                    opacity: 0,
                    x: -50,
                    ease: Back.easeOut,
                    duration: 1.7
                },
                ">-1.3"
            );

            timeLine3.from(skillPostman, 
                {
                    opacity: 0,
                    x: -50,
                    ease: Back.easeOut,
                    duration: 1.7
                },
                ">-1.3"
            );

            timeLine3.from(skillGit, 
                {
                    opacity: 0,
                    x: -50,
                    ease: Back.easeOut,
                    duration: 1.7
                },
                ">-1.3"
            );

        });

    }, [])


    return(
        <div className="grid md:grid-cols-2 lg:grid-cols-4 w-full gap-7 md:gap-6 lg:gap-10 ">
            {
                dataSkills.map((skill, i) => (
                    <div key={i} className='skill-card flex cursor-pointer relative transition-colors-1 group' onClick={() => getWikipediaAPI(skill.wikiTitle)} id={skill.id}>
                        <LazyLoadImage src={skill.icon} alt={`Icon ${skill.name}`} className='w-1/4 group-hover:skew-y-12 group-hover:sepia transition-all duration-500' />
                        
                        <div className="flex flex-col justify-center ml-4">
                            <h1 className="font-bold text-xl md:text-sm lg:text-lg">{skill.name}</h1>
                            <h2 className="font-medium">{skill.level}</h2>
                        </div>

                        <div className="px-3 md:px-4 absolute bottom-3 right-5 font-medium text-textPrimaryDark dark:text-textPrimaryDark bg-colorLight2 dark:bg-colorDark2 rounded-full text-center text-xs lg:text-sm transition-colors-1">
                            <h1>{skill.tag}</h1>
                        </div>
                    </div>
                ))
            }

            <SkillExplanationModal getAPILoading={getAPILoading} wikipediaResults={wikipediaResults} showWikipediaDataModal={showWikipediaDataModal} closeWikipediaModal={closeWikipediaModal} />
        </div>
    )

}

export default AboutSectionSkills
